import { useAuth0 } from '@auth0/auth0-react'
import Box from '@mui/material/Box'
import MuiButton from '@mui/material/Button'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import useDefaultRoute from 'hooks/useDefaultRoute'
import ScentreGroupLogo from 'public/ScentregroupLogo.png'

import useStyles from './styles'

const noLinkPaths = ['/', '/terms-and-conditions']
const landingLinkPaths = ['/confirm-request-access']

const ImageLogo = (
  <Box display="flex">
    <Image
      src={ScentreGroupLogo}
      alt="Scentre Group logo"
      width={240}
      height={20}
      data-test-id="sclogo"
      placeholder="blur"
    />
  </Box>
)

const LinkLogo = ({ route }: { route: string }) => {
  const classes = useStyles({})
  return (
    <Link passHref href={route} legacyBehavior>
      <MuiButton className={classes.image}>{ImageLogo}</MuiButton>
    </Link>
  )
}
export const Logo = ({ pathname }: { pathname: string }) => {
  const { user } = useAuth0()
  const defaultRoute = useDefaultRoute(user)
  if (noLinkPaths.includes(pathname)) return ImageLogo

  if (landingLinkPaths.includes(pathname)) return <LinkLogo route={'/'} />

  return <LinkLogo route={defaultRoute} />
}
