import React from 'react'
import Alert from '@mui/material/Alert'
import MUISnackbar from '@mui/material/Snackbar'
import Slide, { SlideProps } from '@mui/material/Slide'
import { OpenSnackbarArgs } from 'lib/types/snackbar'

type TransitionProps = Omit<SlideProps, 'direction'>

const transitions = {
  left: (props: TransitionProps) => <Slide {...props} direction="left" />,
  up: (props: TransitionProps) => <Slide {...props} direction="up" />,
  right: (props: TransitionProps) => <Slide {...props} direction="right" />,
  down: (props: TransitionProps) => <Slide {...props} direction="down" />,
}

const handleClose = (
  callback: () => void | null,
  event: React.SyntheticEvent<any> | Event,
  reason?: string
) => {
  if (reason === 'clickaway') return
  else callback()
}

const Snackbar = ({
  open,
  message,
  severity = 'info',
  duration = 4000,
  position = { vertical: 'bottom', horizontal: 'left' },
  direction = 'right',
  icon,
  action,
  closeSnackbar,
  'data-test-id': testId,
}: OpenSnackbarArgs & { open: boolean; closeSnackbar: () => null | void }) => (
  <MUISnackbar
    open={open}
    autoHideDuration={duration}
    anchorOrigin={position}
    onClose={(event, reason) => handleClose(closeSnackbar, event, reason)}
    TransitionComponent={transitions[direction]}
    data-test-id={testId}
  >
    <Alert
      action={action}
      onClose={event => handleClose(closeSnackbar, event)}
      severity={severity}
      variant="filled"
      icon={icon}
    >
      {message}
    </Alert>
  </MUISnackbar>
)

export default Snackbar
