import { ApolloProvider } from '@apollo/client'
import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { CacheProvider, EmotionCache } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AppProps } from 'next/app'
import Head from 'next/head'
import Router, { useRouter } from 'next/router'
import Script from 'next/script'
import React from 'react'
import { RecoilRoot } from 'recoil'
import { ApplicationContextProvider } from 'components/ApplicationContext/ApplicationContext'
import BaseLayout from 'components/BaseLayout/BaseLayout'
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary'
import Footer from 'components/Footer/Footer'
import Login from 'components/Login/Login'
import NavBar from 'components/NavBar/NavBar'
import NavDrawer from 'components/NavDrawer/NavDrawer'
import PageContent from 'components/PageWrapper/PageContent'
import PageWrapper from 'components/PageWrapper/PageWrapper'
import TrackRoot from 'components/Track/TrackRoot'
import { BannerProvider } from 'hooks/useBanner'
import { DialogProvider } from 'hooks/useDialog'
import { SnackbarProvider } from 'hooks/useSnackbar'
import { apolloClient } from 'lib/apolloClient'
import {
  AUTH0_AUDIENCE,
  AUTH0_AUTH_MAX_AGE_SECONDS,
  AUTH0_DOMAIN,
  AUTH0_SPA_CLIENT_ID,
  FEAT_GOOGLE_TAG_MANAGER,
  GTM_ACCOUNT,
  gtmAuthParamStr,
  gtmCookiesWinStr,
  gtmPreviewParamStr,
} from 'lib/config'
import createEmotionCache from 'lib/createEmotionCache'
import { disableNextPageview } from 'lib/gtm'
import 'lib/mui-x/license'
import theme from 'lib/theme'
import '../styles/globals.css'

const isBrowser = typeof window !== 'undefined'

const clientSideEmotionCache = createEmotionCache()

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

function onRedirectCallback(appState?: AppState | undefined): void {
  disableNextPageview()
  Router.replace(appState?.returnTo || '/marketing-hub')
}

function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  const AnyComponent = Component as any
  const AnyErrorBoundary = ErrorBoundary as any

  const redirectUri = isBrowser
    ? `${window.location.origin}/oauth/auth0/callback`
    : undefined
  const router = useRouter()
  const { pathname } = router

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Scentre Group | Westfield Marketing Hub</title>
      </Head>

      {/* Google Tag Manager */}
      {FEAT_GOOGLE_TAG_MANAGER && (
        <Script
          id="ga4"
          strategy="afterInteractive"
          async={true}
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){
                      w[l]=w[l]||[];
                      w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
                      var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+'${gtmAuthParamStr}${gtmPreviewParamStr}${gtmCookiesWinStr}';
                      f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${GTM_ACCOUNT}');
                  `,
          }}
        />
      )}
      {/* End Google Tag Manager */}

      <Auth0Provider
        useRefreshTokens
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_SPA_CLIENT_ID}
        authorizationParams={{
          audience: AUTH0_AUDIENCE,
          maxAge: AUTH0_AUTH_MAX_AGE_SECONDS,
          redirectUri,
        }}
        cacheLocation="localstorage"
        onRedirectCallback={onRedirectCallback}
        useRefreshTokensFallback
      >
        <ApolloProvider client={apolloClient}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={theme}>
              <RecoilRoot>
                <TrackRoot>
                  <CssBaseline />
                  <Login route={pathname}>
                    <ApplicationContextProvider>
                      <div className="app">
                        <SnackbarProvider>
                          <DialogProvider>
                            <BannerProvider>
                              <NavBar />
                              <PageWrapper>
                                <NavDrawer />
                                <PageContent>
                                  <BaseLayout route={pathname}>
                                    <AnyErrorBoundary>
                                      <AnyComponent {...pageProps} />
                                    </AnyErrorBoundary>
                                  </BaseLayout>
                                  <Footer route={pathname} />
                                </PageContent>
                              </PageWrapper>
                            </BannerProvider>
                          </DialogProvider>
                        </SnackbarProvider>
                      </div>
                    </ApplicationContextProvider>
                  </Login>
                </TrackRoot>
              </RecoilRoot>
            </ThemeProvider>
          </LocalizationProvider>
        </ApolloProvider>
      </Auth0Provider>
    </CacheProvider>
  )
}

export default MyApp
