// Centre level
export const centreLevelOptions: Array<{
  label: string
  value?: string
}> = [
  { label: 'Choose Level', value: '' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: 'B', value: 'B' },
  { label: 'B1', value: 'B1' },
  { label: 'B2', value: 'B2' },
  { label: 'G', value: 'G' },
  { label: 'LG', value: 'LG' },
  { label: 'M', value: 'M' },
  { label: '1M', value: '1M' },
  { label: '2M', value: '2M' },
  { label: '3M', value: '3M' },
  { label: 'P1', value: 'P1' },
  { label: 'P2', value: 'P2' },
  { label: 'P3', value: 'P3' },
  { label: 'P4', value: 'P4' },
]

// Statuses
export const reviewStatusTypes = ['Submitted', 'Not Submitted', 'Other']
