import { PaletteColorOptions } from '@mui/material'
import { createTheme, Theme } from '@mui/material/styles'
import localFont from '@next/font/local'

const proximaNova = localFont({
  display: 'swap',
  src: [
    {
      path: '../public/fonts/ProximaNova-Regular.woff2',
      weight: 'normal',
      style: 'normal',
    },
    {
      path: '../public/fonts/ProximaNova-Regular-Italic.woff2',
      weight: 'normal',
      style: 'italic',
    },
    {
      path: '../public/fonts/ProximaNova-Light.woff2',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../public/fonts/ProximaNova-Bold.woff2',
      weight: 'bold',
      style: 'normal',
    },
    {
      path: '../public/fonts/ProximaNova-Extrabld.woff2',
      weight: '800',
      style: 'normal',
    },
  ],
})

// Date pickers type augmentation
// https://v5.mui.com/x/react-date-pickers/getting-started/#typescript
import type {} from '@mui/x-date-pickers/themeAugmentation'

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles' {
  interface CustomPalette {
    lavender: PaletteColorOptions
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    lavender: true
  }
}

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '&.Mui-disabled': {
          opacity: '0.6',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: '#aaaaa5',
          '&.Mui-active': {
            color: '#ff1c33',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: '20px',
          height: '20px',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: '#2e0029',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        vertical: {
          marginLeft: '9px',
          marginTop: '10px',
        },
        lineVertical: {
          borderLeftWidth: '2px',
          minHeight: '20px',
        },
      },
    },
  },
  breakpoints: {
    values: {
      sm: 1024,
      md: 1439,
      lg: 1919,
      // unset from design
      xs: 728,
      xl: 1919,
    },
  },
  typography: {
    fontFamily: proximaNova.style.fontFamily,
    h4: {
      fontWeight: 'bold',
      fontSize: '34px',
      padding: '20px 0px',
    },
    h5: {
      fontWeight: 'bold',
      fontSize: '24px',
      padding: '8px 0px',
    },
    h6: {
      fontWeight: 'bold',
      fontSize: '20px',
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 'bold',
    },
    subtitle2: {
      fontWeight: 'bold',
      fontSize: '14px',
    },
    body1: {
      fontSize: '16px',
    },
    body2: {
      fontSize: '14px',
    },
    button: {
      fontSize: '15px',
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: '12px',
    },
    overline: {
      fontSize: '10px',
      textTransform: 'uppercase',
    },
  },
})

export default createTheme(theme, {
  palette: {
    primary: {
      light: '#593b80',
      main: '#2e0029',
      dark: '#0f0000',
    },
    secondary: {
      light: '#ff625e',
      main: '#ff1c33',
      dark: '#c71414',
    },
    error: {
      light: '#f6685e',
      main: '#f44336',
      dark: '#aa2e25',
    },
    warning: {
      light: '#ab6600',
      main: '#985a00',
      dark: '#824d00',
    },
    info: {
      light: '#573353',
      main: '#2e0029',
      dark: '#20001c',
    },
    success: {
      light: '#6fbf73',
      main: '#4CAF50',
      dark: '#357a38',
    },
    grey: {
      100: '#FCFCFA',
    },
    lavender: theme.palette.augmentColor({
      color: {
        main: '#a1889e',
      },
    }),
    divider: 'rgba(0,0,0,0.12)',
  },
})

export const SPACING = {
  NONE: 0,
  XXS: 1,
  XS: 2,
  S: 3,
  M: 4,
  L: 5,
  XL: 8,
}

// Some of gutter spacing are not whole units etc 12px, 20px
// Since we are using Box, Stacks and Grids which usually have own paddings,
// Sometimes its needed to have a complementary spacing on each side to achieve certain spacings
export const GUTTER_SPACING = {
  XS: 1.5,
  S: 2.5,
}

type PageFlags = {
  [key: string]: (route: string) => boolean
}

export const PAGE_FLAGS: PageFlags = {
  FULL_WIDTH: (route: string) => {
    const routes: string[] = [
      '/marketing-hub/my-stores',
      '/marketing-hub/posts',
      '/scentre-hub/my-account/my-contacts',
      '/scentre-hub/support/contact-directory',
      '/admin',
      '/admin/retailers',
      '/',
      '/confirm-request-access',
    ]
    return routes.some((r: string) => route === r)
  },
  SMALL_WIDTH: (route: string) => {
    const routes: string[] = [
      '/scentre-hub/my-account/my-contacts/new',
      '/beta/my-account/my-contacts/new',
      '/beta/my-account/my-contacts',
      '/beta/support/contact-directory',
      '/beta/reporting/sales-performance-data',
      '/marketing-hub',
      '/marketing-hub',
      '/marketing-hub/campaigns/calendar',
      '/marketing-hub/campaigns/posts/new',
      '/marketing-hub/campaigns/offer',
      '/marketing-hub/campaigns/news',
      '/marketing-hub/campaigns/event',
    ]
    return routes.some((r: string) => route.startsWith(r))
  },
  MOBILE_FRIENDLY: (route: string) => {
    const routes: string[] = ['/', '/confirm-request-access']
    return routes.some((r: string) => route === r)
  },
}

export const DYNAMIC_PAGE_URL_FULL_WIDTH = (route: string): boolean => {
  // `route` doesn't actually give us the retailerId, so we're
  // just checking for the existence of next.js dynamic route syntax
  const rules = [/^\/admin\/retailers\/\[[^\]]*\]$/]
  for (const regExp of rules) {
    if (regExp.test(route)) {
      return true
    }
  }
  return false
}

export const determineMinWidth = (route: string, theme: Theme) => {
  if (PAGE_FLAGS.MOBILE_FRIENDLY(route)) {
    return '100%'
  } else if (PAGE_FLAGS.SMALL_WIDTH(route)) {
    return theme.breakpoints.values.sm
  }
  return theme.breakpoints.values.xs
}
