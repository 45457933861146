import { reduce as reduceHierarchicalList } from 'lib/hierarchyUtils'
import { HierarchicalAutocompleteValue, HierarchicalOption, Option } from './types'

/**
 * Maps the provided hierarchical option to an option
 *
 * @param hierarchicalOptionOrValue - The option or value
 * @param isLeaf - Whether the option is a leaf node
 * @param depth - The depth the hierarchical option sits at in the hierarchical list
 * @param disabled - Whether the option should be disabled
 * @param hasOnlySiblingsOfEqualHeight - Whether the option solely has siblings of equal height. This is false if the
 *                                       option has any siblings who themselves have children.
 */
export const mapToOption = (
  hierarchicalOptionOrValue: HierarchicalAutocompleteValue & Partial<HierarchicalOption>,
  isLeaf = false,
  depth = 0,
  disabled?: boolean,
  hasOnlySiblingsOfEqualHeight = false,
): Option => {
  return {
    label: hierarchicalOptionOrValue.label || '',
    id: hierarchicalOptionOrValue.id,
    isLeaf,
    hasOnlySiblingsOfEqualHeight: hasOnlySiblingsOfEqualHeight,
    disabled: disabled != null ? disabled : hierarchicalOptionOrValue.disabled === true,
    depth,
  }
}

/**
 * Maps the provided hierarchical options to a flat list of options
 *
 * @param hierarchicalOptions
 * @param depth
 */
export const mapToOptions = (hierarchicalOptions: HierarchicalOption[], depth = 0): Option[] => {
  let hasSiblingsOfEqualHeight = hierarchicalOptions.every((hierarchicalOption) => !hierarchicalOption.children.length)

  return hierarchicalOptions.flatMap((hierarchicalOption) => {
    const children = mapToOptions(hierarchicalOption.children, depth + 1)

    return [
      mapToOption(hierarchicalOption, !children.length, depth, undefined, hasSiblingsOfEqualHeight),
      ...children,
    ]
  })
}

/**
 * Maintains the hierarchical tree of the specified options based off the specified hierarchical options through
 * grafting any missing tree ancestors of each option up to each options' top-most ancestor.
 *
 * Any grafted ancestor is assumed to be inaccessible and as such is marked as disabled.
 *
 * @param hierarchicalOptions The hierarchical options that the specified options were based off
 * @param options A set of (filtered) options
 *
 * @returns The grafted hierarchical list as options
 */
export function maintainOptionHierarchy(
  hierarchicalOptions: HierarchicalOption[],
  options: Option[],
) {
  const optionMap = options.reduce(
    (result, option) => ({ ...result, [option.id]: option }),
    {} as { [index: string]: Option },
  )

  const traverseHierarchicalOption = (hierarchicalOption: HierarchicalOption, depth = 0): Option[] => {
    let option: Option | null = optionMap[hierarchicalOption.id]

    // Traverse the children
    const children = hierarchicalOption.children
      .flatMap((item) => traverseHierarchicalOption(item, depth + 1))

    // If the current hierarchical option is not included in the list of options then only include if any of its
    // children are.
    if (!option && children?.length) {
      option = mapToOption(hierarchicalOption, false, depth, true)
    }

    if (option) {
      return [option, ...children]
    }

    return []
  }

  const result = hierarchicalOptions.flatMap(traverseHierarchicalOption)

  if (result.length === 0) {
    // This shouldn't happen if the options are based off the hierarchical options, but let's handle this case
    // regardless and return the options as is if so
    return options
  }

  return result
}

/**
 * Creates a hierarchical autocomplete value map of the specified hierarchical options keyed by the option id
 *
 * @param hierarchicalOptions
 */
export const mapHierarchicalOptionsById = (hierarchicalOptions: HierarchicalOption[]) =>
  reduceHierarchicalList(hierarchicalOptions, (result, option) => ({
    ...result,
    [option.id]: {
      id: option.id,
      label: option.label,
    },
  }), {} as { [index: string]: HierarchicalAutocompleteValue })