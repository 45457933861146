import React, { ReactNode } from 'react'
import useStyles from './styles'

const StickyContainer = ({
  children,
  topSpacing,
}: {
  children: ReactNode
  topSpacing?: string | number
}) => {
  const classes = useStyles({ topSpacing })
  return <div className={classes.root}>{children}</div>
}

export default StickyContainer
