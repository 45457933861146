import { User } from '@auth0/auth0-react'
import { TabProps } from '@mui/material/Tab'
import Link from 'next/link'
import { useMemo, ElementType, Attributes } from 'react'
import useCurrentAppSection, { AppSection } from 'hooks/useCurrentAppSection'
import { FEAT_BETA_TAB } from 'lib/config'
import { hasBetaAccess, hasWmhAccess } from 'lib/permission'

export type CustomTabProps<ComponentType extends ElementType> = TabProps<ComponentType, { 'data-test-id': string, component: ComponentType }> & Attributes

const useTabs = (user?: User) => {
  const currentAppSection = useCurrentAppSection()

  const tabLookup = useMemo(() => {
    // Show the `Scentre Hub (BETA)` tab if the FF is configured, and the user either has beta access or is an WMH admin
    const showBetaTab = FEAT_BETA_TAB && hasBetaAccess(user)

    // Show the `Scentre Hub` tab if the user has wmh access and the beta tab is not shown
    const showScentreHubTab = hasWmhAccess(user) && !showBetaTab

    // Show the `Marketing Hub` tab
    const showMarketingHubTab = hasWmhAccess(user)

    const lookup: { [key in AppSection]?: CustomTabProps<typeof Link> } = {}

    if (showMarketingHubTab) {
      lookup['marketing-hub'] = {
        key: 'marketing-hub',
        label: 'Marketing Hub',
        component: Link,
        href: '/marketing-hub',
        value: 'marketing-hub',
        'data-test-id': 'navbar-marketing-hub',
      }
    }

    if (showScentreHubTab) {
      lookup['scentre-hub'] = {
        key: 'scentre-hub',
        label: 'Scentre Hub',
        component: Link,
        href: '/scentre-hub/support/contact-directory',
        value: 'scentre-hub',
        'data-test-id': 'navbar-scentre-hub',
      }
    }

    if (showBetaTab) {
      lookup['beta'] = {
        key: 'beta',
        label: "Scentre Hub (BETA)",
        component: Link,
        href: "/beta",
        value: 'beta',
        'data-test-id': 'navbar-scentre-hub-beta',
      }
    }

    return lookup
  }, [user])

  const tabElements = Object.values(tabLookup)
  const tabs = Object.keys(tabLookup)
  const selectedTab = tabs.includes(currentAppSection) ? currentAppSection : tabs?.[0]

  return {
    tabs: tabElements,
    selectedTab,
  }
}

export default useTabs