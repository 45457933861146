import React, { ErrorInfo, ReactNode } from 'react'
import ErrorPage from 'pages/error'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

export class ErrorBoundary extends React.Component<Props, State> {
  static getDerivedStateFromError(_: Error): State {
    return { hasError: true }
  }

  state: State = { hasError: false }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error: ', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />
    } else {
      return this.props.children
    }
  }
}
