import { selector } from 'recoil'
import { myRetailer } from '../atoms/retailer'

export const currentRetailerDetail = selector({
  key: 'selectedRetailer',
  get: ({ get }) => {
    const { currentRetailerId, retailers } = get(myRetailer)

    return retailers?.find(retailer => retailer.id === currentRetailerId)
  },
})

export const currentRetailerDetailId = selector({
  key: 'selectedRetailerId',
  get: ({ get }) => {
    const { currentRetailerId } = get(myRetailer)
    return currentRetailerId
  },
})

export const currentRetailers = selector({
  key: 'currentRetailers',
  get: ({ get }) => {
    const { retailers } = get(myRetailer)

    return retailers
  },
})
