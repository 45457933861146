import { Auth0ContextInterface, User } from '@auth0/auth0-react'
import { AUTH0_METADATA_NS, FEAT_BETA_TAB } from 'lib/config'
import { Account } from 'lib/types/account'

export enum Permission {
  VisibilityStoresAll = 'visibility:stores:all',
  VisibilityStoresConstrained = 'visibility:stores:constrained',
  UpdateBrand = 'update:brand',
  VisibilityReports = 'visibility:reports',
  ReadContacts = 'read:contacts',
  UpdateContacts = 'update:contacts',
  CreateContacts = 'create:contacts',
  SystemAdmin = 'systemadmin'
}

export enum AccountPermission {
  // Contact Management
  DeleteContacts = 'delete_contacts',
  ReadContacts = 'read_contacts',
  UpdateContacts = 'update_contacts',
  WriteContacts = 'write_contacts',

  // Reports
  ReadReports = 'read_reports',
}

type Retailer = {
  id: string
  permissions: Permission[]
  roles: string[]
}

type UserCustomMetadata = {
  permissions: Permission[]
  retailers: Retailer[]
  hasBetaAccess?: boolean
  hasWmhAccess?: boolean
} & Auth0ContextInterface

const getUserMetadata = (user: User): UserCustomMetadata => user[AUTH0_METADATA_NS]

/**
 * Determines whether the specified user has the specified user-level permission
 *
 * @param permission The permission to check
 * @param user The user to check permissions against
 */
export const hasUserPermission = (permission: Permission, user: User | null | undefined) => {
  if (!user) {
    return false
  }

  const userMetadata: UserCustomMetadata = getUserMetadata(user)

  return userMetadata?.permissions?.includes(permission) || false
}

/**
 * Determines whether the specified user has the specified retailer-level permission of the retailer with specified id
 *
 * @param permission The permission to check
 * @param user The user that is associated with the retailer
 * @param retailerId The id of the retailer that the user is associated with to check permissions against
 */
export const hasUserRetailerPermission = (permission: Permission, user: User | null | undefined, retailerId: string | null | undefined) => {
  if (!user || !retailerId) {
    return false
  }

  const userMetadata: UserCustomMetadata = getUserMetadata(user)
  const retailerDetails = userMetadata?.retailers?.find((retailer: Retailer) => retailer.id === retailerId)

  return retailerDetails?.permissions.includes(permission) || false
}

/**
 * Determines whether the specified user has the specified account-level permission of the account
 *
 * @param accountPermission The permission to check
 * @param account The account to check
 */
export const hasUserAccountPermission = (accountPermission: AccountPermission, account?: Pick<Account, 'permissions'> | null) => {
  if (!account) {
    return false
  }

  return account.permissions?.includes(accountPermission) || false
}

/**
 * Determines whether the specified user has beta access
 *
 * @param user
 */
export const hasBetaAccess = (user?: User): boolean => {
  if (!user || !FEAT_BETA_TAB) {
    return false
  }

  const userMetadata: UserCustomMetadata = getUserMetadata(user)

  return userMetadata?.hasBetaAccess === true
}

/**
 * Determines whether the specified user has beta access
 *
 * @param user
 */
export const hasWmhAccess = (user?: User): boolean => {
  if (!user) {
    return false
  }

  const userMetadata: UserCustomMetadata = getUserMetadata(user)

  return userMetadata?.hasWmhAccess ?? true
}