import { ReactNode } from 'react'
import { Dayjs } from 'dayjs'
import { Variant as TypographyVariant } from '@mui/material/styles/createTypography'

export type CTA = {
  url?: string
  newWindow?: boolean
}

export type Card = {
  id: number | string
  image?: {
    src?: string
    optimise?: boolean
    prioritise?: boolean
  }
  topicId?: string
  kicker: string
  title: string
  description?: string
  buttonText?: string
  cta?: CTA
  colour?: string
  minHeight?: number | string
  maxHeight?: number | string
  handleClick?: () => void
}

export interface ICardContent {
  children: ReactNode
  'data-test-id': string
}

export interface ICardMedia {
  src: string
  alt: string
  'data-test-id': string
  width: number
  height: number
  optimiseImage?: boolean
  prioritiseImage?: boolean
}

export interface ICardKicker {
  text: string
  'data-test-id': string
  colour?: string
}

export interface ICardTitle {
  text: string
  'data-test-id': string
  variant?: TypographyVariant
  colour?: string
}

export interface ICardDescription {
  text: string
  'data-test-id': string
  colour?: string
}

export interface ICardCTA {
  text: string
  'data-test-id': string
  href?: string | undefined
  colour?: string
  handleClick?: () => void
}

export interface ICard {
  children: ReactNode
  'data-test-id': string
  cta?: CTA
  colour?: string
  cardTabbable?: boolean
  handleClick?: () => void
  minHeight?: number | string
  maxHeight?: number | string
}

export type CampaignCard = {
  sys:{
    id: string
  }
  title: string
  topicId?: string | undefined
  submittedBy?: Date | Dayjs
  startDate: Date | Dayjs
  endDate: Date | Dayjs
  thumbnailImageSet: {
    masterImage: {
          src: string
          fileName: string
    }
  }
  heroImageSet: {
    masterImage: {
          url: string
          fileName: string
    }
  }
}

export type FormattedCampaignCard = {
  description: string
  kicker: string
  id: string
  image?: {
    src: string
  }
  topicId?: string
  title: string
  cta?: CTA
} & CampaignCard

export type AFeaturePage = {
  url: string
  id: string | number
  title: string
  date: Date | Dayjs
  endDate?: Date | Dayjs
  image: {
    url: string
    alt: string
  }
}

export type AFeature = {
  kicker: string
  title: string
  ctaLabel: string
  ctaUrl: string
  image: {
    src: string
    alt: string
    width: number
    height: number
  }
  pages: AFeaturePage[]
}

// These are lowercase as the fields are coming from Contentful.
export enum ColourScheme {
  Light = 'light',
  Dark = 'dark',
  Navy = 'navy',
}
