import { makeStyles } from '@mui/styles'
import { SPACING } from 'lib/theme'

export default makeStyles(theme => ({
  toolbar: {
    gap: theme.spacing(SPACING.S),
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    flexGrow: 0,
  },
  navLinks: {
    display: 'flex',
    flexGrow: 1,
    paddingLeft: theme.spacing(SPACING.L),
    alignItems: 'stretch',
    '& .MuiTabs-indicator': {
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      bottom: '5px'
    },
    '& a': {
      color: 'white',
      margin: `0px ${theme.spacing(SPACING.S)}`,
    },
    '& a.Mui-selected': {
      color: 'white',
      fontWeight: 'bold',
    },
  },
  navItem: {
    padding: '20px 25px',
    margin: '1px',
  },
  navItemActive: {
    border: '2px solid white',
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(SPACING.XXS),
    '& .MuiSvgIcon-root': {
      width: '24px',
      height: '24px',
    },
  },
  logout: {
    color: 'white',
    fontSize: '13px',
  },
  image: {
    color: theme.palette.common.white,
    paddingLeft: SPACING.NONE,
    paddingRight: SPACING.NONE,
  },
  headingContainer: {
    width: `${theme.breakpoints?.values?.sm}px`,
    maxWidth: `${theme.breakpoints?.values?.sm}px`,
    backgroundColor: theme.palette.grey[50],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(SPACING.XS),
    paddingLeft: theme.spacing(SPACING.S),
    paddingRight: theme.spacing(SPACING.S),
  },
}))
