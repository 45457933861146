import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { isPathMatchingRoute } from 'lib/utils'

export const PUBLIC_PAGES = [
  {
    url: '/preview',
    exact: false,
  },
  {
    url: '/confirm-request-access',
    exact: false,
  },
  {
    url: '/',
    exact: true,
  },
  {
    url: '/oauth/auth0/callback',
    exact: false,
  },
]

const usePublicPage = (route: string | null = null) => {
  const { pathname } = useRouter()

  const urlToCompare = (route ?? pathname) || ''

  return useMemo(
    () =>
      PUBLIC_PAGES.some(page =>
        isPathMatchingRoute(urlToCompare, page)
      ),
    [pathname]
  )
}

export default usePublicPage