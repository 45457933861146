import React, { useState, createContext, ReactNode } from 'react'
import Snackbar from 'components/Snackbar/Snackbar'
import { SnackbarArgs, OpenSnackbarArgs } from 'lib/types/snackbar'

type SnackbarContextProviderValue = {
  snackbar: SnackbarArgs
  openSnackbar: (args1: OpenSnackbarArgs) => void
  closeSnackbar: () => void
}

export const SnackbarContext = createContext({} as SnackbarContextProviderValue)

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const initialState: SnackbarArgs = {
    open: false,
    message: '',
    severity: 'info',
    duration: 3000,
    position: { vertical: 'bottom', horizontal: 'left' },
    direction: 'right',
    icon: undefined,
    action: undefined,
    'data-test-id': 'notificationSnackbar',
  }

  const [snackbar, setSnackbar] = useState(initialState)
  const openSnackbar = (args1: OpenSnackbarArgs) =>
    setSnackbar({ ...args1, open: true })
  const closeSnackbar = () => setSnackbar({ ...snackbar, open: false })

  return (
    <SnackbarContext.Provider value={{ snackbar, openSnackbar, closeSnackbar }}>
      <Snackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        duration={snackbar.duration}
        position={snackbar.position}
        direction={snackbar.direction}
        icon={snackbar.icon}
        action={snackbar.action}
        closeSnackbar={closeSnackbar}
        data-test-id={snackbar['data-test-id']}
      />
      {children}
    </SnackbarContext.Provider>
  )
}

export default () => React.useContext(SnackbarContext)
