export const DIMENSION_NA = 'DIMENSION_NA'

export enum TrackFormType {
  INITIAL = 'track-form-enable',
  UPDATE = 'track-form-update',
}

export type GTMDimensions = {
  userId?: string
  retailerId?: string
  retailerName?: string
  contentType?: string
  contentId?: string
  contentTitle?: string
  topicId?: string
  topicTitle?: string
}

type HitDimensions = {
  contentType: boolean
  contentId: boolean
  contentTitle: boolean
  topicId: boolean
  topicTitle: boolean
}

export type PathsHitDimensions = {
  path: string
  atoms: Partial<HitDimensions>
}

export enum TrackIdType {
  ID,
  NAME,
}