import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { isPathMatchingRoute } from 'lib/utils'

type Route = { url: string, exact: boolean }

export const SCENTRE_HUB_BASE_ROUTES: Route[] = [
  {
    url: '/scentre-hub',
    exact: false,
  },
]

export const BETA_BASE_ROUTES: Route[] = [
  {
    url: '/beta',
    exact: false,
  },
]

export const MARKETING_HUB_BASE_ROUTES: Route[] = [
  {
    url: '/marketing-hub',
    exact: false,
  },
  {
    url: '/admin',
    exact: false,
  },
]

export type AppSection = 'marketing-hub' | 'scentre-hub' | 'beta' | 'misc'

const hasMatchingRoute = (routes: Route[], pathname: string) => routes.some(route => isPathMatchingRoute(pathname, route))

const useCurrentAppSection = (): AppSection => {
  const { pathname } = useRouter()

  return useMemo(
    () => {
      if (hasMatchingRoute(SCENTRE_HUB_BASE_ROUTES, pathname)) {
        return 'scentre-hub'
      }

      if (hasMatchingRoute(BETA_BASE_ROUTES, pathname)) {
        return 'beta'
      }

      if (hasMatchingRoute(MARKETING_HUB_BASE_ROUTES, pathname)){
        return 'marketing-hub'
      }

      return 'misc'
    },
    [pathname]
  )
}

export default useCurrentAppSection