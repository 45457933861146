import * as Sentry from '@sentry/nextjs'
import { Integrations } from '@sentry/tracing'
import { SENTRY_DSN, SENTRY_ENV } from './lib/config'

const tracesSampleRate = SENTRY_ENV === 'production' ? 0.25 : 0

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  tracesSampleRate,
  integrations: [new Integrations.BrowserTracing()],
})
