import React, { useState, createContext } from 'react'
import { BannerArgs, SetBanner } from 'lib/types/banner'
import Banner from 'components/Banner/Banner'

type BannerContextProviderValue = {
  banner: BannerArgs
  setBanner: SetBanner
  handleBannerClose: () => void
}

export const BannerContext = createContext({} as BannerContextProviderValue)

export const BannerProvider = ({ children }: { children: React.ReactNode }) => {
  const initialBannerState = {
    open: false,
    message: '',
    actionText: '',
    cancelText: '',
  } as BannerArgs

  const [banner, setBanner] = useState(initialBannerState)
  const handleBannerClose = () => setBanner(initialBannerState)

  return (
    <BannerContext.Provider
      value={{
        banner: banner,
        setBanner,
        handleBannerClose,
      }}
    >
      <Banner />
      {children}
    </BannerContext.Provider>
  )
}

const useBanner = () => React.useContext(BannerContext)

export default useBanner
