import { useAuth0 } from '@auth0/auth0-react'
import React, { createContext, PropsWithChildren } from 'react'
import { useRecoilValue } from 'recoil'
import { currentUserAccountAtom } from 'lib/atoms/account'
import { FEAT_BETA_TAB, LOCAL_STORAGE_KEYS } from 'lib/config'
import { hasBetaAccess } from 'lib/permission'
import { CurrentUserAccount } from 'lib/types/account'
import { UserProfile } from 'lib/types/user'
import useInitCurrentUserAccount from './useInitCurrentUserAccount'
import useInitSetCurrentUserAccount, { SetCurrentUserAccount } from './useInitSetCurrentUserAccount'
import useInitUserProfile from './useInitUserProfile'

type ApplicationContext = {
  userProfile?: UserProfile | null
  currentUserAccount?: CurrentUserAccount | null
  setCurrentUserAccount?: SetCurrentUserAccount | null
}

export const ApplicationContext = createContext({} as ApplicationContext)

const BetaProvider = ({ children }: PropsWithChildren) => {
  const { user } = useAuth0()
  const userProfile = useInitUserProfile(user)
  const currentUserAccount = useRecoilValue(currentUserAccountAtom)
  const setCurrentUserAccount = useInitSetCurrentUserAccount(currentUserAccount, userProfile)
  const currentUserAccountId = localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_USER_ACCOUNT_ID)
  useInitCurrentUserAccount({
    userProfile,
    currentUserAccount,
    currentUserAccountId,
    setCurrentUserAccount
  })

  return (
    <ApplicationContext.Provider
      value={{
        userProfile,
        currentUserAccount,
        setCurrentUserAccount,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  )
}

const Provider = ({ children }: PropsWithChildren) => {
  return (
    <ApplicationContext.Provider
      value={{
        userProfile: null,
        currentUserAccount: null,
        setCurrentUserAccount: null,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  )
}

/**
 * An application wide context provider for assisting with conditional loading of WMH and beta application state, and
 * for acting as a single source of truth for user profile and current user account data.
 *
 * The beta provider is loaded if the feature flag is enabled and the current logged-in user has beta access, else
 * the standard provider is loaded.
 *
 * Since the beta feature will be rolled out gradually, and user by user, there's no need to initialise the beta state
 * if the current user doesn't have access.
 */
export const ApplicationContextProvider = (props: PropsWithChildren) => {
  const { user } = useAuth0()

  if (FEAT_BETA_TAB && hasBetaAccess(user)) {
    return <BetaProvider {...props} />
  } else {
    return <Provider {...props} />
  }
}