import { AtomEffect } from 'recoil'

export const storageEffect: <T>(key: string, storageType?: 'local' | 'session') => AtomEffect<T> =
  (key: string, storageType = 'local') =>
  ({ setSelf, onSet }) => {
    const isBrowser = typeof window !== 'undefined'

    if (isBrowser) {
      const storage = storageType === 'session' ? sessionStorage : localStorage

      const savedValue = storage.getItem(key)
      if (savedValue != null) {
        try {
          setSelf(JSON.parse(savedValue))
        } catch {
          // If its not parsable json, Delete the storage key
          storage.removeItem(key)
        }
      }

      onSet((newValue, oldValue) => {
        if (newValue === oldValue) {
          storage.removeItem(key)
        } else {
          storage.setItem(key, JSON.stringify(newValue))
        }
      })
    }
  }

export const localStorageEffect = (key: string) => storageEffect(key, 'local')
