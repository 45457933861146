import { makeStyles } from '@mui/styles'
import { SPACING } from 'lib/theme'

export default makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      minWidth: '1024px',
      borderRadius: 0,
    },
    card: {
      padding: 0,
    },
    cardContent: {
      padding: theme.spacing(SPACING.L),
      paddingBottom: theme.spacing(SPACING.L),
    },
    cardTitle: {
      display: 'inline-block',
      float: 'left',
      width: 'auto',
    },
    cardActions: {
      paddingRight: theme.spacing(SPACING.S),
      paddingBottom: theme.spacing(SPACING.XS),
    },
    contentContainer: {
      maxWidth: '860px',
    },
    icon: {
      display: 'inline-block !important',
      float: 'left !important' as 'left',
      marginRight: theme.spacing(SPACING.XXS),
    },
    bottomPadding: {
      padding: `0 0 ${theme.spacing(SPACING.S)} 0!important`,
    },
  }),
  { name: 'MuiBanner' }
)
