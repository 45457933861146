import React, { ReactNode } from 'react'
import Box from '@mui/material/Box'
import useStyles from './styles'

const PageWrapper = ({ children }: { children: ReactNode }) => {
  const classes = useStyles()

  return (
    <Box className={classes.pageWrapper}>
      {children}
    </Box>
  )
}

export default PageWrapper
