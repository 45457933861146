import React, { useEffect } from 'react'
import SelectMUI from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import { useRecoilState, useSetRecoilState } from 'recoil'
import useStyles from './styles'
import useTracking from 'hooks/useTracking'
import { TrackIdType } from 'lib/types/gtm'
import { AUTH0_METADATA_NS, LOCAL_STORAGE_KEYS } from 'lib/config'
import { myRetailer, retailerStores } from 'lib/atoms/retailer'
import { useAuth0 } from '@auth0/auth0-react'
import useSnackbar from 'hooks/useSnackbar'
import { SNACKBAR_CONTENTS } from 'lib/snackbarContents'
import { Retailer } from 'lib/types/retailer'
import TrackBox from 'components/Track/TrackBox'
import { campaignPostStep } from 'lib/atoms/campaignPost'
import useSWR from 'swr'
import { fetcher } from 'lib/api'
import { SWR_CONFIG } from 'lib/config'
import { MySelectDisplayProps } from 'lib/types/form'

type Props = {
  name?: string
  'data-test-id'?: string
}

const RetailerSwitcher = ({ name, 'data-test-id': testId }: Props) => {
  const classes = useStyles()
  const { openSnackbar } = useSnackbar()
  const [retailer, setRetailer] = useRecoilState(myRetailer)
  const setStores = useSetRecoilState(retailerStores)
  const setCurrentStep = useSetRecoilState(campaignPostStep)
  const { currentRetailerId } = retailer
  const { user } = useAuth0()
  const { trackFieldBlur } = useTracking()

  const retailers: Retailer[] =
    user &&
    user[AUTH0_METADATA_NS]?.retailers?.map(
      ({ sfId, ...remainingProps }: { sfId: string }) => ({
        salesforceAccountId: sfId,
        ...remainingProps,
      })
    )

  const defaultCurrentRetailerId = user && user[AUTH0_METADATA_NS]?.retailerId

  const { data: storeData, error: isError } = useSWR(
    currentRetailerId ? `${currentRetailerId}/stores` : null,
    url => fetcher(url),
    SWR_CONFIG
  )

  useEffect(() => {
    setStores({
      storeData,
      isError,
    })
  }, [storeData, isError])

  const getCurrentRetailerId = (): string => {
    const lastRetailerId = localStorage.getItem('lastRetailerId')

    if (
      lastRetailerId !== 'null' &&
      lastRetailerId !== null &&
      retailers.some(r => r.id === lastRetailerId)
    ) {
      return lastRetailerId
    } else {
      return defaultCurrentRetailerId
    }
  }

  const getRetailerById = (
    retailerId: string,
    retailers: Retailer[]
  ): Retailer | null => retailers.find(r => r.id === retailerId) || null

  function clearPersistData() {
    setCurrentStep(0)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.POST_DETAIL)
    sessionStorage.removeItem(LOCAL_STORAGE_KEYS.POST_ID)
    sessionStorage.removeItem(LOCAL_STORAGE_KEYS.POST_TYPE)
  }

  function handleChangeRetailer(retailerId: string) {
    // gets the index of the selected retailer on the array of retailers
    const currentRetailer = getRetailerById(retailerId, retailer.retailers)

    setRetailer({
      ...retailer,
      currentRetailer,
      currentRetailerId: retailerId,
    })

    sessionStorage.setItem(LOCAL_STORAGE_KEYS.RETAILER_ID, retailerId)

    if (currentRetailer) {
      openSnackbar(SNACKBAR_CONTENTS.SWITCH_RETAILER(currentRetailer.title))
    }

    clearPersistData()
  }

  const handleOnBlur = () => {
    if (name) {
      trackFieldBlur(name, TrackIdType.NAME)
    }
  }

  useEffect(() => {
    if (!currentRetailerId && retailers?.length) {
      // Here we test if the lastRetailerId value from localStorage is a null string
      // localStorage returns a null string rather that a null data type
      const storedCurrentRetailerId = getCurrentRetailerId()
      const currentRetailer = getRetailerById(
        storedCurrentRetailerId,
        retailers
      )

      setRetailer({
        currentRetailerId: storedCurrentRetailerId,
        currentRetailer,
        retailers,
      })
    }

    const handleUnload = () =>
      currentRetailerId &&
      localStorage.setItem('lastRetailerId', currentRetailerId)

    window.addEventListener('beforeunload', handleUnload)

    return () => window.removeEventListener('beforeunload', handleUnload)
  }, [defaultCurrentRetailerId, currentRetailerId, retailers])

  if (!retailers?.length) {
    return null
  }

  return (
    <SelectMUI
      className={classes.select}
      name={name}
      variant="outlined"
      defaultValue={getCurrentRetailerId()}
      onChange={e => handleChangeRetailer(e.target.value as string)}
      onBlur={handleOnBlur}
      SelectDisplayProps={
        {
          'data-test-id': testId,
        } as MySelectDisplayProps
      }
    >
      {retailers.map(option => (
        <TrackBox
          groupId="sidebar-accounts"
          id={option.title}
          component={MenuItem}
          key={option.id}
          value={option.id}
          data-test-id={option.title}
        >
          <Box className={classes.option}>
            <Avatar alt={`${option.title} logo`} src={option.avatar} />
            <Box width={'100%'}>{option.title}</Box>
          </Box>
        </TrackBox>
      ))}
    </SelectMUI>
  )
}

export default RetailerSwitcher
