import { Box, BoxProps, CircularProgress } from '@mui/material'
import React from 'react'
import useStyles from './styles'

const DEFAULT_SIZE = 60

type props = {
  size?: number,
  center?: boolean
} & BoxProps

export default function LoadingContainer({
  size = DEFAULT_SIZE,
  center = false,
  ...containerProperties
}: props) {
  const classes = useStyles({ center })

  return (
    <Box
      className={classes.container}
      alignItems="center"
      height={center ? '100vh' : '70%'}
      data-test-id="loading-container"
      {...containerProperties}>
      <CircularProgress color="secondary" size={size} />
    </Box>
  )
}
