import { FieldError } from 'react-hook-form'

export type SupportingImageProps = {
  label?: string
  cropper: boolean
  disabled?: boolean
  images: ImageType[] | ImageFileType[]
  handleRemoveImage: (asset: { index: number; type: string }) => void
  handleCropComplete: (asset: {
    id: string
    url: string
    type: string | null
    index: number
  }) => void
  handleCropReset: (asset: {
    type: string | null
    index?: number | null
  }) => void
  'data-test-id'?: string
  isUploading?: boolean
}

export type HeroImageCropCompleteProps = {
  id?: string
  type: CropType
  url?: string
  published?: boolean
  skipDimension?: boolean
}

export type HandleHeroImageCropResetProps = {
  type: string | null
  index?: number | null | undefined
}

export type CropType =
  | 'master'
  | 'square'
  | 'standard'
  | 'wide'
  | 'SUPPORTING_IMAGE'
  | null

export type CropProps = {
  id?: string
  type: CropType
  index?: number | null
  image?: string
  assetLocation?: AssetLocation
  published?: boolean
  url?: string
  skipDimension?: boolean
}

export enum AssetLocation {
  CONTENTFUL = 'Contentful',
  BYNDER = 'Bynder',
}

export type ImageType = {
  id?: string | null
  name?: string | null
  url?: string | null
  preview?: string | null
  masterSupportingColour?: string
  published?: boolean | null
  isSquare?: boolean
  contentType?: string | null
  assetLocation?: AssetLocation
  fileSize?: string | null
  extension?: string | null
  type?: string | null
}

export type ImageFileType = File & {
  preview: string
}

export interface HeroImageSet extends ImageType {
  id: string | null
  backendTitle?: string | null
  master: ImageType
  standard: ImageType
  square: ImageType
  coloured?: ImageType
  transparent?: ImageType
  tileBackground?: ImageType
  masterSupportingColour?: string
}

export type RetailerLogoSet = {
  id: string | null
  coloured: {
    id?: string | null
    url?: string | null
    published?: boolean | null
    assetLocation?: AssetLocation
  }
  transparent: {
    id?: string | null
    url?: string | null
    published?: boolean | null
    assetLocation?: AssetLocation
  }
  tileBackground: {
    id?: string | null
    url?: string | null
    published?: boolean | null
    assetLocation?: AssetLocation
  }
}

export type HeroImageSetFieldError = FieldError & {
  master: {
    id: FieldError
  }
}

export type SupportingImageSetFieldError = FieldError & {
  images: FieldError
}

export interface StaticImageData {
  src: string
  height: number
  width: number
  blurDataURL?: string
}
