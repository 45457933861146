/* eslint-disable no-magic-numbers */
import { atom } from 'recoil'
import { POST_TYPE } from 'lib/config'
import { VIEW_OPTION } from 'lib/utils'
import { AssetUploadData, CampaignPostData } from 'lib/types/form'

export const defaultFormState = {
  viewOption: VIEW_OPTION.EVERYONE,
  formType: POST_TYPE.Offer,
  memberExclusive: false,
  topic: {},
} as unknown as CampaignPostData

export const campaignPostStep = atom({
  key: 'campaignPostStep',
  default: 0,
})

export const campaignPostFormData = atom({
  key: 'campaignPostFormData',
  default: defaultFormState,
})

export const changingPostTypeFrom = atom({
  key: 'changingPostTypeFrom',
  default: '',
})

export const isMediaUploadingAtom = atom({
  key: 'isMediaUploadingAtom',
  default: false,
})

export const assetUploadDataAtom = atom({
  key: 'assetUploadDataAtom',
  default: {
    groupId: null,
    uploadDate: null,
  } as AssetUploadData,
})
