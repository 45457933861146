import Typography from '@mui/material/Typography'
import React from 'react'
import { PARTNER_SUPPORT_EMAIL } from 'lib/config'
import useStyles from '../styles/errorPageStyle'

function ErrorPage() {
  const classes = useStyles()

  return (
    <div className={classes.errorContent}>
      <Typography variant="h4">An error has occured</Typography>
      <Typography variant="subtitle1">
        Please refresh the page or{' '}
        <a href={`mailto:${PARTNER_SUPPORT_EMAIL}`}>
          <Typography variant="subtitle1" component="span" color="secondary">
            Contact us
          </Typography>
        </a>{' '}
        if the issue cannot be resolved
      </Typography>
    </div>
  )
}

export default ErrorPage
