import { atom } from 'recoil'

export const trackCustomUrlAtom = atom({
  key: 'trackCustomUrlAtom',
  default: '',
})

export const trackContentTypeAtom = atom({
  key: 'trackContentTypeAtom',
  default: '',
})

export const trackContentIdAtom = atom({
  key: 'trackContentIdAtom',
  default: '',
})

export const trackContentTitleAtom = atom({
  key: 'trackContentTitleAtom',
  default: '',
})

export const trackTopicIdAtom = atom({
  key: 'trackTopicIdAtom',
  default: '',
})

export const trackTopicTitleAtom = atom({
  key: 'trackTopicTitleAtom',
  default: '',
})
