import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { DateTimeFormat } from './types/dateUtils'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)

const userTimeZone = dayjs.tz.guess() || 'Australia/Sydney'
dayjs.tz.setDefault(userTimeZone)

// TODO: Refactor formatDate to accommodate time format as well
export const formatDate = (
  date: Date | Dayjs | string | null | undefined,
  format: DateTimeFormat
) => (date && dayjs(date).isValid() ? dayjs(date).format(format) : '')

export const sortDateComparator = (
  date1: Date | string,
  date2: Date | string
) => dayjs(date1).diff(date2)

export const getCurrentDate = () => dayjs().second(0).millisecond(0)

export const parseDateOnly = (date: Date | string) =>
  date && dayjs(date).format('YYYY-MM-DD')

export const parseTimeOnly = (time: Date | string) =>
  time && dayjs(time, 'HH:mm a')

export const formatTimeForApi = (date?: Date | null) =>
  date && dayjs(date).format('HH:mm')

export const combineDateAndTime = (
  date: string | null,
  time: string | null
): string => formatDate(`${date}T${time}`, DateTimeFormat.API)

export const addDay = (date: Date) => (date ? dayjs(date).add(1, 'days') : null)

export const isAfter = (targetDate?: Date | Dayjs, dateTo?: Date | Dayjs) =>
  targetDate && dateTo ? dayjs(targetDate).isAfter(dateTo) : false

export const getDateAfterNDays = (days: number) => {
  const date = new Date()
  date.setDate(date.getDate() + days)
  return date
}
