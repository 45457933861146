import { atom } from 'recoil'
import { Retailer } from 'lib/types/retailer'
import { AxiosResponse } from 'axios'

interface RetailerAtom {
  id?: string
  currentRetailerId: string | null
  currentRetailer: Retailer | null
  retailers: Retailer[]
}

export const myRetailer = atom({
  key: 'myRetailer',
  default: {
    currentRetailerId: null,
    currentRetailer: null,
    retailers: [],
  } as RetailerAtom,
})

interface retailerStoresAtom {
  storeData?: AxiosResponse<any>
  isError?: any
}
export const retailerStores = atom({
  key: 'retailerStores',
  default: {
    storeData: {
      data: {
        items: [],
        total: 0,
      },
    },
    isError: null,
  } as retailerStoresAtom,
})
