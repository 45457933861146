import React from 'react'
import { ApplicationContext } from 'components/ApplicationContext/ApplicationContext'
import { currentUserAccountStorageKey } from 'lib/atoms/account'

export const useSetCurrentUserAccount = () => {
  const appContext = React.useContext(ApplicationContext)
  return appContext.setCurrentUserAccount
}

export const clearCurrentUserAccount = () => {
  // Removing in both storages as a precautionary measure for cases when the atom storage location changes
  sessionStorage.removeItem(currentUserAccountStorageKey)
  localStorage.removeItem(currentUserAccountStorageKey)
}

const useCurrentUserAccount = () => {
  const appContext = React.useContext(ApplicationContext)
  return appContext.currentUserAccount
}

export default useCurrentUserAccount