import React from 'react'
import { ApplicationContext } from 'components/ApplicationContext/ApplicationContext'
import { profileStorageKey } from 'lib/atoms/user'
import { find as findInHierarchicalList } from 'lib/hierarchyUtils'
import { UserProfileAccountTreeItem } from 'lib/types/user'

const useUserProfile = () => {
  const appContext = React.useContext(ApplicationContext)
  return appContext.userProfile
}

export const findAccountByAccountNumber = (accounts: UserProfileAccountTreeItem[], accountNumber?: string, isAccessible?: boolean): UserProfileAccountTreeItem | null => {
  if (!accountNumber) {
    return null
  }

  return findInHierarchicalList(
    accounts,
    (account) => account.accountNumber === accountNumber && (!isAccessible || account.hasAccess),
  )
}

export const findFirstAccessibleAccount = (accounts: UserProfileAccountTreeItem[]): UserProfileAccountTreeItem | null => {
  return findInHierarchicalList(accounts, (account) => account.hasAccess)
}

export const clearUserProfile = () => {
  // Removing in both storages as a precautionary measure for cases when the atom storage location changes
  sessionStorage.removeItem(profileStorageKey)
  localStorage.removeItem(profileStorageKey)
}

export default useUserProfile