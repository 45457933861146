import { useAuth0 } from '@auth0/auth0-react'
import PersonIcon from '@mui/icons-material/Person'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/styles'
import { useRouter } from 'next/router'
import React from 'react'
import { useRecoilValue } from 'recoil'
import useTabs from 'components/NavBar/useTabs'
import TrackBox from 'components/Track/TrackBox'
import TrackBoxGroup from 'components/Track/TrackBoxGroup'
import { clearCurrentUserAccount } from 'hooks/useCurrentUserAccount'
import usePublicPage from 'hooks/usePublicPage'
import useSnackbar from 'hooks/useSnackbar'
import { clearUserProfile } from 'hooks/useUserProfile'
import { hasBootstrappedLoginAtom } from 'lib/atoms/user'
import { SNACKBAR_CONTENTS } from 'lib/snackbarContents'
import { Logo } from './Logo'
import useStyles from './styles'

const NavBar = () => {
  const theme = useTheme()
  const { user, logout } = useAuth0()
  const { openSnackbar } = useSnackbar()

  const { pathname } = useRouter()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles({ isMobile })

  const isPublicPage = usePublicPage()
  const hasBootstrappedLogin = useRecoilValue(hasBootstrappedLoginAtom)

  function clearPersistData() {
    localStorage.clear()
    clearUserProfile()
    clearCurrentUserAccount()
  }

  const { tabs, selectedTab } = useTabs(user)

  return (
    <AppBar position="static" elevation={0}>
      <TrackBoxGroup id="navbar" transparent={true}>
        <Toolbar className={classes.toolbar}>
          <div>
            <TrackBox id="logo" flexGrow={1}>
              <Logo pathname={pathname} />
            </TrackBox>
          </div>
          {!isPublicPage && hasBootstrappedLogin && (
            <>
              <div className={classes.navLinks}>
                <Tabs value={selectedTab}>
                  {
                    tabs.map((tabProps) => <Tab key={tabProps.key} {...tabProps}  />)
                  }
                </Tabs>
              </div>
              <Box className={classes.user}>
                <PersonIcon />
                <Typography variant="subtitle2" data-test-id="navbar-username">
                  {user?.name || user?.email}
                </Typography>
              </Box>
              <TrackBox id="logout">
                <Button
                  variant="outlined"
                  color="lavender"
                  data-test-id="navbar-logout"
                  className={classes.logout}
                  onClick={() => {
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                    clearPersistData()
                    openSnackbar(SNACKBAR_CONTENTS.LOGOUT)
                  }}
                >
                  Logout
                </Button>
              </TrackBox>
            </>
          )}
        </Toolbar>
      </TrackBoxGroup>
    </AppBar>
  )
}

export default NavBar