import { User } from '@auth0/auth0-react'
import useCurrentAppSection from 'hooks/useCurrentAppSection'
import { hasBetaAccess, hasWmhAccess } from 'lib/permission'

/**
 * Determine if the current logged-in user has access to the current app section
 * To do so, a blanket restriction per app section is applied based on the users access levels
 */
const useAppSectionAccess = (user?: User) => {

  const appSection = useCurrentAppSection()

  let hasAccessToAppSection = false

  switch (appSection) {
    case 'beta':
      hasAccessToAppSection = hasBetaAccess(user)
      break
    case 'marketing-hub':
    case 'scentre-hub':
      hasAccessToAppSection = hasWmhAccess(user)
      break;
    case 'misc':
      hasAccessToAppSection = true
      break;
  }

  return hasAccessToAppSection
}

export default useAppSectionAccess