import fetch from 'cross-fetch'
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context';
import {
  SC_CONTENTFUL_SPACE_ID,
  SC_CONTENTFUL_ENV_ALIAS,
  SC_CONTENTFUL_DELIVERY_API_ACCESS_TOKEN,
} from 'lib/config'

const GQL_URL = `https://graphql.contentful.com/content/v1/spaces/${SC_CONTENTFUL_SPACE_ID}/environments/${SC_CONTENTFUL_ENV_ALIAS}`

const httpLink = new HttpLink({ uri: GQL_URL, fetch })

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: `Bearer ${SC_CONTENTFUL_DELIVERY_API_ACCESS_TOKEN}`,
  }
}))

const initial_campaignCollection = {
  "__typename": "CampaignCollection",
  "items": [],
  "total": 0,
  "skip": 0
}

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          campaignCollection: {
            keyArgs: false,
            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing = initial_campaignCollection, incoming) {
              return {...incoming, "items": [...existing.items, ...incoming.items]};
            },
          }
        }
      }
    }
  })
})
