// TO-DO: Improve naming for DateTimeFormat
export enum DateTimeFormat {
  /** This format is used for date to be submitted to the server */
  API = 'YYYY-MM-DDTHH:mm',
  /** This format is used for date to be rendered on Article */
  ARTICLE = 'Do MMM YYYY',
  CAMPAIGN = 'ddd D MMM',
  CAMPAIGN_CALENDAR = 'MM-DD-YYYY',
  DEFAULT = 'YYYY-MM-DD HH:MM:ss',
  FULLSHORT = 'D/M/YYYY',
  SOCIAL = 'YYYYMMDD_HHmmss',
  /** This format is used for dates appended in backendTitle / title */
  TITLE = 'DD-MM-YYYY',
  /** This format is used for dates to be rendered in the UI */
  UI = 'DD/MM/YYYY hh:mm a',
}
