import {
  trackCustomUrlAtom,
  trackContentIdAtom,
  trackContentTitleAtom,
  trackContentTypeAtom,
  trackTopicIdAtom,
  trackTopicTitleAtom,
} from 'lib/atoms/track'
import {
  eventTrackFormSubmit,
  trackFieldBlurById,
  trackFieldBlurByName,
  trackFieldDropById,
  trackFieldDropByName,
} from 'lib/gtm'
import { TrackIdType } from 'lib/types/gtm'
import { useSetRecoilState } from 'recoil'

const useTracking = () => {
  const setTrackCustomUrl = useSetRecoilState(trackCustomUrlAtom)
  const setTrackContentType = useSetRecoilState(trackContentTypeAtom)
  const setTrackContentId = useSetRecoilState(trackContentIdAtom)
  const setTrackContentTitle = useSetRecoilState(trackContentTitleAtom)
  const setTrackTopicId = useSetRecoilState(trackTopicIdAtom)
  const setTrackTopicTitle = useSetRecoilState(trackTopicTitleAtom)

  // Custom Urls can only be set for pageviews with hit scope dimensions (ie. when also tracking content/topic)
  // Must be called before trackContent/trackTopic, so pageview will be sent with the custom url.
  const trackCustomUrl = (customUrl: string) => {
    setTrackCustomUrl(customUrl)
  }

  // All props/hit dimensions are required before related pageview event will be sent.
  // If caller wishes to deliberately set a prop to false, they can pass DIMENSION_NA from lib/types/gtm.
  const trackContent = (type: string, id: string, title: string) => {
    setTrackContentType(type)
    setTrackContentId(id)
    setTrackContentTitle(title)
  }

  // All props/hit dimensions are required before related pageview event will be sent.
  // If caller wishes to deliberately set a prop to false, they can pass DIMENSION_NA from lib/types/gtm.
  const trackTopic = (id: string, title: string) => {
    setTrackTopicId(id)
    setTrackTopicTitle(title)
  }

  const trackSubmitSuccess = (formId: string) => {
    eventTrackFormSubmit(formId, true)
  }

  const trackSubmitFailure = (formId: string) => {
    eventTrackFormSubmit(formId, false)
  }

  // Send native dom event, to assist GTM tracking, as some components do not by default.
  const trackFieldBlur = (id: string, idType: TrackIdType = TrackIdType.ID) => {
    if (idType === TrackIdType.ID) {
      trackFieldBlurById(id)
    } else {
      trackFieldBlurByName(id)
    }
  }

  const trackFieldDrop = (id: string, idType: TrackIdType = TrackIdType.ID) => {
    if (idType === TrackIdType.ID) {
      trackFieldDropById(id)
    } else {
      trackFieldDropByName(id)
    }
  }

  return {
    trackCustomUrl,
    trackContent,
    trackTopic,
    trackSubmitSuccess,
    trackSubmitFailure,
    trackFieldBlur,
    trackFieldDrop,
  }
}

export default useTracking
