import React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { OpenSnackbarArgs } from './types/snackbar'

export const SNACKBAR_CONTENTS = {
  POST_SUBMISSION_SUCCESS: {
    message: 'Your post has been submitted',
    severity: 'success',
    'data-test-id': 'campaigns-submit-success-snackbar',
  } as OpenSnackbarArgs,
  POST_DRAFT_SUCCESS: {
    message: 'Your post has been saved as a draft',
    severity: 'success',
    'data-test-id': 'campaigns-draft-success-snackbar',
  } as OpenSnackbarArgs,
  POST_DUPLICATE_SUCCESS: {
    message: 'Your post has been duplicated',
    severity: 'info',
    'data-test-id': 'campaigns-duplicate-success-snackbar',
  } as OpenSnackbarArgs,
  POST_DUPLICATE_ERROR: {
    message: 'There was a problem duplicating your post. Please try again.',
    severity: 'error',
    'data-test-id': 'campaigns-duplicate-error-snackbar',
  } as OpenSnackbarArgs,
  POST_NO_LONGER_EXISTS_ERROR: {
    message: 'This post no longer exists',
    severity: 'info',
    'data-test-id': 'campaigns-no-longer-exists-success-snackbar',
  } as OpenSnackbarArgs,
  POST_VALIDATION_ERROR: {
    message: 'Sorry your post has some errors. Please check the fields.',
    severity: 'error',
    duration: 20000,
    'data-test-id': 'campaigns-submit-error-snackbar',
  } as OpenSnackbarArgs,

  // social snackbar
  SOCIAL_SUBMISSION_SUCCESS: {
    message: 'Your content has been submitted',
    severity: 'success',
    'data-test-id': 'social-submit-success-snackbar',
  } as OpenSnackbarArgs,
  SOCIAL_VALIDATION_ERROR: {
    message:
      'Sorry your social content has some errors. Please check the fields.',
    severity: 'error',
    duration: 20000,
    'data-test-id': 'social-submit-error-snackbar',
  } as OpenSnackbarArgs,
  SOCIAL_GENERIC_ERROR: {
    message: 'Something went wrong, please try again.',
    severity: 'error',
    'data-test-id': 'social-error-snackbar',
  } as OpenSnackbarArgs,

  getPostTaskError: (messages: string[]) => ({
    title: 'A message from the moderation team regarding your post',
    message: (
      <Grid container spacing={2}>
        {messages.map((message: string, i: number) => (
          <Grid item xs={12} key={i}>
            <Typography variant="body2" color="primary">
              {message}
            </Typography>
          </Grid>
        ))}
      </Grid>
    ),
    actionText: 'OK, GOT IT',
  }),

  DELETE_IMAGE: {
    message: 'Your image was deleted',
    severity: 'info',
    'data-test-id': 'image-delete-info-snackbar',
  } as OpenSnackbarArgs,
  DELETE_VIDEO: {
    message: 'Your video was deleted',
    severity: 'info',
    'data-test-id': 'video-delete-info-snackbar',
  } as OpenSnackbarArgs,
  IMAGE_UPLOAD_SUCCESS: {
    message: 'Image uploaded',
    severity: 'info',
    'data-test-id': 'image-upload-info-snackbar',
  } as OpenSnackbarArgs,
  MEDIA_UPLOAD_SUCCESS: {
    message: 'Media uploaded',
    severity: 'info',
    'data-test-id': 'media-upload-info-snackbar',
  } as OpenSnackbarArgs,
  MEDIA_UPLOAD_WAIT: {
    message: 'Your upload may take a few minutes, please be patient',
    severity: 'info',
    duration: 20000,
    'data-test-id': 'media-upload-wait-info-snackbar',
  } as OpenSnackbarArgs,
  POST_DELETE_SUCCESS: {
    message: 'Post successfully deleted',
    actionText: 'DISMISS',
    severity: 'info',
    'data-test-id': 'post-delete-success-snackbar',
  } as OpenSnackbarArgs,
  POST_DELETE_ERROR: {
    message: 'Unable to delete post',
    actionText: 'DISMISS',
    severity: 'error',
    'data-test-id': 'post-delete-fail-snackbar',
  } as OpenSnackbarArgs,
  POST_UNPUBLISH_SUCCESS: {
    message: 'Post successfully unpublished',
    actionText: 'DISMISS',
    severity: 'info',
    'data-test-id': 'post-unpublish-success-snackbar',
  } as OpenSnackbarArgs,
  POST_UNPUBLISH_ERROR: {
    message: 'Unable to unpublish post',
    actionText: 'DISMISS',
    severity: 'error',
    'data-test-id': 'post-unpublish-fail-snackbar',
  } as OpenSnackbarArgs,
  PREVIEW_DATA_NOT_FOUND: {
    title: 'It looks like you haven’t entered all the content that is required',
    message:
      'Please jump back into Contentful and have a look. You can refresh this page or preview again from Contentful to see the changes you have made.',
    actionText: 'REFRESH',
    cancelText: 'OK GOT IT',
    'data-test-id': 'preview-data-not-found-snackbar',
  },
  PREVIEW_DATA_CHANGED_OR_DRAFT: {
    title: 'It looks like this content is in draft / changed status.',
    message:
      'It will need to be sent to published in Contentful to be eligible for inclusion or for updates to be reflected in email newsletters.',
    actionText: 'REFRESH',
    cancelText: 'OK GOT IT',
    'data-test-id': 'preview-data-changed-snackbar',
  },
  IMAGE_CROP_SUCCESS: {
    message: 'Your image has been cropped',
    severity: 'info',
    'data-test-id': 'image-crop-info-snackbar',
  } as OpenSnackbarArgs,

  // admin/user snackbar
  USER_VALIDATION_ERROR: (message: string) =>
    ({
      message,
      severity: 'error',
      duration: 20000,
      'data-test-id': 'user-create-validation-error-snackbar',
    } as OpenSnackbarArgs),
  USER_CREATE_SUCCESS: {
    message: 'A new user has been created successfully',
    severity: 'success',
    'data-test-id': 'user-create-success-snackbar',
  } as OpenSnackbarArgs,
  USER_CREATE_ERROR: (errorId: string) =>
    ({
      message: `Something went wrong creating user, error id: ${errorId}`,
      severity: 'error',
      duration: 20000,
      'data-test-id': 'user-create-error-snackbar',
    } as OpenSnackbarArgs),
  USER_UPDATE_SUCCESS: {
    message: 'User successfully updated',
    severity: 'success',
    'data-test-id': 'user-update-success-snackbar',
  } as OpenSnackbarArgs,
  USER_UPDATE_ERROR: (errorId: string) =>
    ({
      message: `Something went wrong creating user, error id: ${errorId}`,
      severity: 'error',
      duration: 20000,
      'data-test-id': 'user-update-error-snackbar',
    } as OpenSnackbarArgs),

  // generic
  FETCHER_ERROR: (errorId: string) =>
    ({
      message: `Something went wrong while fetching data, error id: ${errorId}`,
      severity: 'error',
      'data-test-id': 'fetcher-error',
    } as OpenSnackbarArgs),

  SUBMIT_ERROR: (errorId: string) =>
    ({
      message: `Something went wrong while submitting the form, error id: ${errorId}`,
      severity: 'error',
      'data-test-id': 'submit-error',
    } as OpenSnackbarArgs),

  // admin snackbar
  USERS_SEARCH_ERROR: (errorId: string) =>
    ({
      message: `Something went wrong with searching users, error id: ${errorId}`,
      severity: 'error',
      'data-test-id': 'admin-user-search-error-snackbar',
    } as OpenSnackbarArgs),
  RETAILERS_SEARCH_ERROR: (errorId: string) =>
    ({
      message: `Something went wrong with searching retailers, error id: ${errorId}`,
      severity: 'error',
      'data-test-id': 'admin-retailer-search-error-snackbar',
    } as OpenSnackbarArgs),

  // my-stores snackbar content
  STORE_UPDATE_SUCCESS: {
    message: 'Updates to your store have been submitted for review',
    severity: 'success',
    'data-test-id': 'store-success-snackbar',
  } as OpenSnackbarArgs,
  STORE_UPDATE_ERROR: {
    message: 'Sorry your form has some errors. Please check the fields.',
    severity: 'error',
    duration: 20000,
    'data-test-id': 'store-error-snackbar',
  } as OpenSnackbarArgs,

  // my-brands snackbar content
  RETAILER_PROFILE_UPDATE_SUCCESS: (retailerTitleCountry: string) =>
    ({
      message: `Updates to ${retailerTitleCountry} have been submitted for review`,
      severity: 'success',
      'data-test-id': 'brand-success-snackbar',
    } as OpenSnackbarArgs),
  RETAILER_PROFILE_UPDATE_ERROR: {
    message: 'Sorry your form has some errors. Please check the fields.',
    severity: 'error',
    duration: 20000,
    'data-test-id': 'brand-error-snackbar',
  } as OpenSnackbarArgs,

  // sideNav snackbar content
  RETAILER_DETAILS_ERROR: (errorIds: string) =>
    ({
      message: `Something went wrong with fetch retailer details, error id: ${errorIds}`,
      severity: 'error',
      'data-test-id': 'retailer-details-error-snackbar',
    } as OpenSnackbarArgs),
  SWITCH_RETAILER: (retailer: string) =>
    ({
      message: `You have switched to ${retailer}`,
      severity: 'info',
      'data-test-id': 'switch-retailer-snackbar',
    } as OpenSnackbarArgs),
  LOGOUT: {
    message: 'You have been successfully logged out',
    severity: 'info',
    'data-test-id': 'logout-snackbar',
  } as OpenSnackbarArgs,
  USER_DELETED: {
    message: 'User has been successfully deleted',
    severity: 'info',
    'data-test-id': 'user-delete-snackbar',
  } as OpenSnackbarArgs,
}

export const SNACKBAR_ERROR_MESSAGE = {
  USER_ALREADY_EXISTS: 'Sorry, You are creating an existing user.',
}
