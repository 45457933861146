import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react'
import { useRouter } from 'next/router'
import React, { ReactElement, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import BaseLayout from 'components/BaseLayout/BaseLayout'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import NavBar from 'components/NavBar/NavBar'
import PageContent from 'components/PageWrapper/PageContent'
import PageWrapper from 'components/PageWrapper/PageWrapper'
import useAppSectionAccess from 'hooks/useAppSectionAccess'
import useDefaultRoute from 'hooks/useDefaultRoute'
import { addAccessTokenInterceptor } from 'lib/api'
import { hasAcceptedTerms as hasAcceptedTermsAtom, hasBootstrappedLoginAtom } from 'lib/atoms/user'
import { AUTH0_METADATA_NS } from 'lib/config'

export const setAccessToken = async (
  getAccessTokenSilently: (
    options?: GetTokenSilentlyOptions,
  ) => Promise<string>,
  { cacheMode = 'on' } = {},
) => {
  await getAccessTokenSilently({ cacheMode } as GetTokenSilentlyOptions)
}

export default function Login({
  route,
  children,
}: {
  route?: string
  children: ReactElement
}) {
  const { user, isAuthenticated, isLoading: isLoadingAuthUser, getAccessTokenSilently } =
    useAuth0()
  const defaultRoute = useDefaultRoute(user)
  const [hasAcceptedTerms, setAcceptedTerms] = useRecoilState(hasAcceptedTermsAtom)
  const [hasBootstrappedLogin, setHasBootstrappedLogin] = useRecoilState(hasBootstrappedLoginAtom)
  const [bootstrapping, setBootstrapping] = useState(true)
  const hasAccessToAppSection = useAppSectionAccess(user)
  const router = useRouter()

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently)
  }, [])

  // Initialises the bootstrap
  useEffect(() => {
    const bootstrapLogin = async () => {
      if (isLoadingAuthUser) {
        setBootstrapping(true)
        setHasBootstrappedLogin(false)

        return
      }

      if (isAuthenticated) {
        await setAccessToken(getAccessTokenSilently)

        let acceptedTerms = false
        if (user) {
          acceptedTerms = user[AUTH0_METADATA_NS].acceptedTermsConditions
        }

        setAcceptedTerms(acceptedTerms)
      }

      setBootstrapping(false)
    }

    bootstrapLogin()
  }, [user, isAuthenticated, isLoadingAuthUser])

  // Completes the bootstrap and guards app section access
  // The purpose of the split between the two effects is to prevent a page flicker when loading the original route
  // and needing to be redirected to the default. This is prevented by setting the hasBootstrappedLogin to true once
  // the route change has been pushed.
  useEffect(() => {
    const finaliseBootstrapping = async () => {
      if (isAuthenticated) {
        if (route !== '/privacy-policy' && !hasAcceptedTerms) {
          // Get user to confirm terms and conditions
          await router.push('/terms-and-conditions')
        } else if (route === '/' || (!hasAccessToAppSection && route !== defaultRoute)) {
          // Redirect to default route if on root route, or if the user doesn't have access to the current app section
          await router.push(defaultRoute)
        }
      }

      setHasBootstrappedLogin(true)
    }

    if (!bootstrapping) {
      finaliseBootstrapping()
    }
  }, [bootstrapping, route, defaultRoute, isAuthenticated, hasAcceptedTerms, hasAccessToAppSection])

  return hasBootstrappedLogin ? children : (
    <>
      <NavBar />
      <PageWrapper>
        <PageContent>
          <BaseLayout>
            <LoadingContainer center />
          </BaseLayout>
        </PageContent>
      </PageWrapper>
    </>
  )
}
