import { FEAT_GOOGLE_TAG_MANAGER, LOCAL_STORAGE_KEYS } from './config'
import {
  DIMENSION_NA,
  GTMDimensions,
  PathsHitDimensions,
  TrackFormType,
} from './types/gtm'

const getWindow = () => window as any

const getDataLayer = () => {
  return getWindow().dataLayer
}

const trackingNotAvailable = () => !FEAT_GOOGLE_TAG_MANAGER || !getDataLayer()

export const pageview = (
  url: string,
  dimensions?: GTMDimensions,
  eventCallback?: () => void
) => {
  if (trackingNotAvailable()) {
    eventCallback?.()
    return
  }

  // Only send a dimension field when it's value exists
  getDataLayer().push({
    event: 'pageview',
    page: url,
    loggedin_userid: dimensions?.userId,
    loggedin_retailerid: dimensions?.retailerId,
    loggedin_retailername: dimensions?.retailerName,
    content_type: dimensions?.contentType,
    content_id: dimensions?.contentId,
    content_title: dimensions?.contentTitle,
    topic_id: dimensions?.topicId,
    topic_title: dimensions?.topicTitle,
    ...(eventCallback ? { eventCallback } : {}),
  })
}

export const eventTrackForm = (type: TrackFormType, formName: string) => {
  if (trackingNotAvailable()) {
    return
  }

  getDataLayer().push({
    event: type,
    'form-name': formName,
  })
}

export const eventTrackFormSubmit = (
  formId: string,
  success: boolean = true
) => {
  if (trackingNotAvailable()) {
    return
  }

  getDataLayer().push({
    event: 'interaction',
    'event-category': 'form',
    'event-action': `${formId} | submit`,
    'event-label': `${
      success ? 'submit-success' : 'submit-fail'
    } | form-actions`,
    'event-value': '0',
    'event-noninteraction': '0',
  })
}

export const dimensionValue = (dimension: string) => {
  if (!dimension || dimension === DIMENSION_NA) return undefined

  return dimension
}

export const sanitizeTrackId = (id: string) => {
  return id.trim().toLowerCase().replace(/\s+/g, '-')
}

// Allows disabling of the next pageview event, when deliberate override is required to stop
// duplicate pageview events being sent to GTM. Eg. Loading '/marketing-hub' following login.
export const disableNextPageview = () => {
  sessionStorage.setItem(LOCAL_STORAGE_KEYS.DISABLE_NEXT_PAGEVIEW, 'true')
}

// Reading this value will also cause the flag to reset itself for the next call.
export const isNextPageviewDisabled = () => {
  const isDisabled =
    sessionStorage.getItem(LOCAL_STORAGE_KEYS.DISABLE_NEXT_PAGEVIEW) === 'true'
  if (isDisabled) {
    sessionStorage.setItem(LOCAL_STORAGE_KEYS.DISABLE_NEXT_PAGEVIEW, 'false')
  }
  return isDisabled
}

// Send native dom event, to assist GTM tracking.
export const trackFieldBlurById = (id: string) => {
  const element = document.getElementById(id)
  if (element) {
    element.dispatchEvent(new Event('blur', { bubbles: true }))
  }
}

// Send native dom event, to assist GTM tracking.
export const trackFieldBlurByName = (name: string) => {
  const elements = document.getElementsByName(name)
  if (elements.length > 0) {
    elements[0].dispatchEvent(new Event('blur', { bubbles: true }))
  }
}

// Send native dom event, to assist GTM tracking.
export const trackFieldDropById = (id: string) => {
  const element = document.getElementById(id)

  if (element) {
    element.dispatchEvent(new Event('ondrop', { bubbles: true }))
  }
}

// Send native dom event, to assist GTM tracking.
export const trackFieldDropByName = (name: string) => {
  const elements = document.getElementsByName(name)
  if (elements.length > 0) {
    elements[0].dispatchEvent(new Event('ondrop', { bubbles: true }))
  }
}

const contentAtoms = {
  contentType: true,
  contentId: true,
  contentTitle: true,
}

const topicAtoms = {
  topicId: true,
  topicTitle: true,
}

export const pathsRequiringHitDimensions: PathsHitDimensions[] = [
  {
    path: '/marketing-hub/articles',
    atoms: {
      ...contentAtoms,
    },
  },
  {
    path: '/marketing-hub/campaigns/posts/new',
    atoms: {
      ...contentAtoms,
      ...topicAtoms,
    },
  },
  {
    path: '/marketing-hub/campaigns/event',
    atoms: {
      ...contentAtoms,
      ...topicAtoms,
    },
  },
  {
    path: '/marketing-hub/campaigns/offer',
    atoms: {
      ...contentAtoms,
      ...topicAtoms,
    },
  },
  {
    path: '/marketing-hub/campaigns/news',
    atoms: {
      ...contentAtoms,
      ...topicAtoms,
    },
  },
  {
    path: '/marketing-hub/campaigns/calendar/',
    atoms: {
      ...contentAtoms,
      ...topicAtoms,
    },
  },
]
export const pageviewWithFlush = (): Promise<void> =>
  new Promise((resolve, _) => {
    pageview(
      window.location.pathname + window.location.search,
      undefined,
      () => {
        resolve(void 0)
      }
    )
  })
