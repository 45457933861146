import { makeStyles } from '@mui/styles'
import { SPACING } from 'lib/theme'

export default makeStyles(theme => ({
  pageWrapper: {
    display: 'flex',
    height: '100%',
    background: theme.palette.grey[50],
  },
  pageContent: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    height: '100%',
    minHeight: `calc(100vh - ${theme.spacing(SPACING.XL)})`,
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
  },
}))
