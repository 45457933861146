import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import {
  dimensionValue,
  isNextPageviewDisabled,
  pageview,
  pathsRequiringHitDimensions
} from 'lib/gtm';
import { useAuth0 } from '@auth0/auth0-react';
import { AUTH0_METADATA_NS, LOCAL_STORAGE_KEYS } from 'lib/config';
import { Router } from 'next/router';
import { BasicRetailer } from 'lib/types/retailer';
import {
  trackContentTypeAtom,
  trackContentIdAtom,
  trackContentTitleAtom,
  trackTopicIdAtom,
  trackTopicTitleAtom,
  trackCustomUrlAtom,
} from 'lib/atoms/track';

type TrackRootProps = {
  isLoginPage?: boolean
  children?: any
};

// This is a GTM helper root component which wraps child components that GTM should track. This needs to be a
// sub-component to Auth0Provider and RecoilRoot, so the dimension data can be loaded.
const TrackRoot = ({ isLoginPage, children }: TrackRootProps) => {
  const { user } = useAuth0()
  const [trackCustomUrl, setTrackCustomUrl] = useRecoilState(trackCustomUrlAtom)
  const [trackContentType, setTrackContentType] = useRecoilState(trackContentTypeAtom)
  const [trackContentId, setTrackContentId] = useRecoilState(trackContentIdAtom)
  const [trackContentTitle, setTrackContentTitle] = useRecoilState(trackContentTitleAtom)
  const [trackTopicId, setTrackTopicId] = useRecoilState(trackTopicIdAtom)
  const [trackTopicTitle, setTrackTopicTitle] = useRecoilState(trackTopicTitleAtom)

  const resetHitDimensionAtoms = () => {
    setTrackCustomUrl('')
    setTrackContentType('')
    setTrackContentId('')
    setTrackContentTitle('')
    setTrackTopicId('')
    setTrackTopicTitle('')
  }

  const determineRetailerId = () => {
    const retailerId = sessionStorage.getItem(LOCAL_STORAGE_KEYS.RETAILER_ID)
    if (retailerId) {
      return retailerId
    }

    return user && user[AUTH0_METADATA_NS]?.retailerId
  }

  const handlePageview = (url: string, isHitDimensionChange?: boolean) => {
    // Reject when deliberately disabled, or user is not available when expected, eg. after login
    if (isNextPageviewDisabled() || (!isLoginPage && !user)) {
      return
    }

    let urlToTrack = url
    let userDimensions = undefined
    let hitDimensions = undefined

    // Check if this route needs Hit level dimension values available before pageview can be sent
    const hitUrlDetails = pathsRequiringHitDimensions.find((uriDetails) => url.startsWith(uriDetails.path))

    if (hitUrlDetails) {
      // Check which hit dimensions are required
      if (
        (hitUrlDetails.atoms.contentId && !trackContentId) ||
        (hitUrlDetails.atoms.contentType && !trackContentType) ||
        (hitUrlDetails.atoms.contentTitle && !trackContentTitle) ||
        (hitUrlDetails.atoms.topicId && !trackTopicId) ||
        (hitUrlDetails.atoms.topicTitle && !trackTopicTitle)
      ) {
        // Block pageview if the required hit dimension values are not yet available
        return
      }

      // Custom url override only supported for pageviews with hit dimensions
      if (trackCustomUrl) {
        urlToTrack = trackCustomUrl
      }

      hitDimensions = {
        contentType: dimensionValue(trackContentType),
        contentId: dimensionValue(trackContentId),
        contentTitle: dimensionValue(trackContentTitle),
        topicId: dimensionValue(trackTopicId),
        topicTitle: dimensionValue(trackTopicTitle),
      }
    } else if (isHitDimensionChange) {
      // If called by a hit dimension change, but no hit dimensions are setup for this url, reject.
      // This should only occur on load of the login screen, but is also a safety check for recoil changes.
      return
    }

    if (!isLoginPage && user) {
      const retailerId = determineRetailerId()
      const retailer = user[AUTH0_METADATA_NS]?.retailers &&
        user[AUTH0_METADATA_NS]?.retailers.find(
          (r: BasicRetailer) => r.id === retailerId
        )

      userDimensions = {
        // TODO: Investigate how this is being set, currently its value is undefined
        // but somehow, some data on the analytics show signs that this previously have value
        userId: user[AUTH0_METADATA_NS]?.userId,
        retailerId: retailerId,
        retailerName: retailer?.title,
      }
    }

    pageview(urlToTrack, {
      // Add User dimensions
      ...userDimensions,
      // Add Hit dimensions
      ...hitDimensions
    })

    resetHitDimensionAtoms()
  }

  useEffect(() => {
    const retailerId = sessionStorage.getItem(LOCAL_STORAGE_KEYS.RETAILER_ID)

    if (!retailerId) {
      const id = localStorage.getItem("lastRetailerId") || ''
      sessionStorage.setItem(LOCAL_STORAGE_KEYS.RETAILER_ID, id)
    }
  }, [])

  // Add listener for initial app load/reload
  useEffect(() => {
    handlePageview(window.location.pathname)
  }, [user])

  // Add listener for route changes
  useEffect(() => {
    Router.events.on('routeChangeComplete', (url) => handlePageview(url))

    return () => {
      Router.events.off('routeChangeComplete', (url) => handlePageview(url))
    }
  }, [Router.events, user])

  // Add listener for GTM hit dimension recoil values
  useEffect(() => {
    handlePageview(window.location.pathname, true)
  }, [
    trackContentType,
    trackContentId,
    trackContentTitle,
    trackTopicId,
    trackTopicTitle,
  ])

  return (
    <>{children}</>
  )
}

TrackRoot.displayName = 'TrackRoot'

export default TrackRoot
