import React from 'react'
import Link from 'next/link'
import { useTheme } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import useStyles from './styles'
import { FAQ_URL, PARTNER_SUPPORT_EMAIL } from 'lib/config'
import TrackBox from 'components/Track/TrackBox'
import TrackBoxGroup from 'components/Track/TrackBoxGroup'
import usePublicPage from 'hooks/usePublicPage'

export default function Footer({ route = '' }: { route?: string }) {
  const isPublicPage = usePublicPage(route)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles({ route, isPublicPage, isMobile })

  return (
    <AppBar
      position="sticky"
      color="primary"
      elevation={0}
      className={classes.root}
      component="footer"
    >
      <TrackBoxGroup id="footer" transparent={true}>
        <Toolbar component="nav" className={classes.toolbar}>
          {!isPublicPage && <PrivateLinks route={route} />}
          <TrackBox id="scentregroup-homepage" transparent={true}>
            <Button
              className={classes.leftButton}
              href="https://www.scentregroup.com"
              target="_blank"
              rel="noreferrer"
            >
              &copy; Scentre Group
            </Button>
          </TrackBox>
        </Toolbar>
      </TrackBoxGroup>
    </AppBar>
  )
}

function PrivateLinks ({ route = '' }: { route?: string }) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles({ route, isPublicPage: true, isMobile })

  return (
    <div className={classes.rightContainer} data-test-id="private-links">
      <TrackBox id="terms-and-conditions" transparent={true}>
        <Link passHref href="/terms-and-conditions" legacyBehavior>
          <Button
            className={classes.rightButton}
            data-test-id="terms-and-conditions"
          >
            Terms &amp; Conditions
          </Button>
        </Link>
      </TrackBox>
      <TrackBox id="faq" transparent={true}>
        <Link
          passHref
          href={FAQ_URL}
          legacyBehavior
        >
          <Button
            className={classes.rightButton}
            data-test-id="link.faq"
            href="#"
            rel="noreferrer"
            target="_blank"
          >
            FAQs
          </Button>
        </Link>
      </TrackBox>
      <TrackBox id="privacy-policy" transparent={true}>
        <Link passHref href="/privacy-policy" legacyBehavior>
          <Button
            className={classes.rightButton}
            data-test-id="link.privacyPolicy"
          >
            Privacy Policy
          </Button>
        </Link>
      </TrackBox>
      <TrackBox id="contact-support" transparent={true}>
        <Button
          className={classes.rightButton}
          href={`mailto:${PARTNER_SUPPORT_EMAIL}`}
          data-test-id="contact-support"
          rel="noreferrer"
          target="_blank"
        >
          Contact Support
        </Button>
      </TrackBox>
    </div>
  )
}
