import React, { useState, createContext, ReactNode } from 'react'
import Dialog from 'components/Dialog/Dialog'
import { DialogContent } from 'lib/types/dialog'

type DialogContextProps = {
  openConfirmationDialog: (data: DialogProviderProps) => void
  openFeedbackDialog: (data: DialogProviderProps) => void
}

export const DialogContext = createContext({} as DialogContextProps)

type DialogProviderProps = DialogContent & {
  confirmCallback: (value: boolean) => void
}

const DialogProvider = ({ children }: { children: ReactNode }) => {
  const [isFeedbackOnly, setIsFeedbackOnly] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [data, setData] = useState({} as DialogProviderProps)

  const openConfirmationDialog: (Props: DialogProviderProps) => void = ({
    title,
    content,
    imageSrc,
    customConfirmLabel,
    customCancelLabel,
    confirmCallback,
  }) => {
    setIsOpen(true)
    setData({
      title,
      content,
      imageSrc,
      customConfirmLabel,
      customCancelLabel,
      confirmCallback,
    })
  }

  const openFeedbackDialog = ({
    title,
    content,
    imageSrc,
    confirmCallback,
  }: DialogProviderProps) => {
    setIsFeedbackOnly(true)
    setIsOpen(true)
    setData({ title, content, imageSrc, confirmCallback })
  }

  const resetDialog = () => {
    setIsOpen(false)
    setIsFeedbackOnly(false)
    setData({} as DialogProviderProps)
  }

  const onConfirm = () => {
    resetDialog()
    data.confirmCallback(true)
  }

  const onCancel = () => {
    resetDialog()
    data.confirmCallback(false)
  }

  return (
    // Dialog Provider provides below two ways of returning Dialog is cancelled or Acknowledged
    // 1. openConfirmationDialog and openFeedbackDialog will return async result
    // 2. isConfirmed will return sync result, so far only is used for leaving page dialog
    <DialogContext.Provider
      value={{ openConfirmationDialog, openFeedbackDialog }}
    >
      <Dialog
        isFeedbackOnly={isFeedbackOnly}
        isOpen={isOpen}
        title={data.title}
        content={data.content}
        imageSrc={data.imageSrc}
        customConfirmLabel={data.customConfirmLabel}
        customCancelLabel={data.customCancelLabel}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
      {children}
    </DialogContext.Provider>
  )
}

const useDialog = () => {
  const { openConfirmationDialog, openFeedbackDialog } =
    React.useContext(DialogContext)

  const showConfirmation = (dialogContent: DialogContent) =>
    new Promise<boolean>(resolve => {
      openConfirmationDialog({ confirmCallback: resolve, ...dialogContent })
    })

  const showFeedback = (dialogContent: DialogContent) =>
    new Promise<boolean>(resolve => {
      openFeedbackDialog({ confirmCallback: resolve, ...dialogContent })
    })

  return { showConfirmation, showFeedback }
}

const useDialogSync = (confirmCallback: (value: boolean) => void) => {
  const { openConfirmationDialog, openFeedbackDialog } =
    React.useContext(DialogContext)

  const showConfirmation = (dialogContent: DialogContent) =>
    openConfirmationDialog({ confirmCallback, ...dialogContent })

  const showFeedback = (dialogContent: DialogContent) =>
    openFeedbackDialog({ confirmCallback, ...dialogContent })

  return { showConfirmation, showFeedback }
}

export { DialogProvider, useDialog, useDialogSync }
