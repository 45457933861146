import { atom } from 'recoil'
import { UserProfile } from 'lib/types/user'
import { storageEffect, localStorageEffect } from '../recoilUtil'
import { LOCAL_STORAGE_KEYS } from '../config'

export const hasAcceptedTerms = atom({
  key: 'hasAcceptedTerms',
  default: false,
  effects_UNSTABLE: [localStorageEffect(LOCAL_STORAGE_KEYS.ACCEPTED_TERMS)],
})

export const hasSeenVideoUploadWelcomeAtom = atom({
  key: 'hasSeenVideoUploadWelcomeAtom',
  default: false,
  effects_UNSTABLE: [localStorageEffect(LOCAL_STORAGE_KEYS.HAS_SEEN_VIDEO_UPLOAD_WELCOME)],
})

export const profileStorageKey = LOCAL_STORAGE_KEYS.USER_PROFILE
export const profile = atom<UserProfile | null>({
  key: 'profile',
  default: null,
  effects_UNSTABLE: [storageEffect(profileStorageKey, 'session')],
})

export const hasBootstrappedLoginAtom = atom<boolean>({
  key: 'hasBootstrappedLogin',
  default: false,
})

