import React, { ElementType, HTMLAttributes } from 'react'
import { BannerContext } from 'hooks/useBanner'
import StickyContainer from 'components/StickyContainer/StickyContainer'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant'
import useStyles from './style'

const getActionButton = (
  label: string,
  onAction: ((actionLabel: string) => false | void | null) | null,
  onClose: () => void | null
) => ({
  label,
  onClick: () => {
    onClose()
    if (onAction) {
      onAction(label)
    }
  },
})

const Banner = () => {
  const classes = useStyles()

  return (
    <BannerContext.Consumer>
      {({
        banner: {
          open,
          title,
          message,
          actionText,
          cancelText,
          onAction = null,
        },
        handleBannerClose,
      }) => {
        let actions = [getActionButton(actionText, onAction, handleBannerClose)]
        if (cancelText) {
          actions = actions.concat([
            getActionButton(cancelText, onAction, handleBannerClose),
          ])
        }
        return (
          <StickyContainer>
            <Collapse in={open} data-test-id="notificationBanner">
              <Paper elevation={3} className={classes.root} component="section">
                <Card
                  elevation={0}
                  component={
                    Container as ElementType<HTMLAttributes<HTMLElement>>
                  }
                  className={classes.card}
                >
                  <CardContent className={classes.cardContent}>
                    <Grid
                      container
                      wrap="nowrap"
                      spacing={2}
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      className={classes.contentContainer}
                    >
                      {title && (
                        <Grid
                          container
                          item
                          className={classes.bottomPadding}
                          direction="row"
                        >
                          <NotificationImportantIcon
                            color="secondary"
                            className={classes.icon}
                          />
                          <Typography variant="subtitle1" className={title}>
                            {title}
                          </Typography>
                        </Grid>
                      )}
                      <Grid item className={classes.bottomPadding}>
                        {typeof message === 'string' ||
                          message instanceof String ? (
                          <Typography variant="body2">{message}</Typography>
                        ) : (
                          message
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                  {actions.length > 0 && (
                    <CardActions className={classes.cardActions}>
                      <Grid
                        container
                        wrap="nowrap"
                        direction="row"
                        justifyContent="flex-end"
                      >
                        {actions.map(({ onClick, label }, i: number) => (
                          <Button
                            variant="text"
                            color="secondary"
                            key={i}
                            onClick={onClick}
                          >
                            {label}
                          </Button>
                        ))}
                      </Grid>
                    </CardActions>
                  )}
                </Card>
              </Paper>
            </Collapse>
          </StickyContainer>
        )
      }}
    </BannerContext.Consumer>
  )
}

export default Banner
