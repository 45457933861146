import { User } from '@auth0/auth0-react'
import { hasBetaAccess, hasWmhAccess } from 'lib/permission'

const useDefaultRoute = (user?: User) => {
  const userHasBetaAccess = hasBetaAccess(user)
  const userHasWmhAccess = hasWmhAccess(user)

  return userHasBetaAccess && !userHasWmhAccess ? '/beta' : '/marketing-hub'
}

export default useDefaultRoute